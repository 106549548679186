<template>
  <div v-if="!isLoading">
    <v-row no-gutters class="justify-space-around align-center ma-3 rounded-lg">
      <v-col cols="1" sm="1" md="1" lg="1" xl="1" align="start">
        <v-hover v-slot="{ hover }">
          <v-btn
            fab
            small
            elevation="3"
            color="primary"
            class="text-truncate"
            :class="hover ? 'base--text' : 'navy--text'"
            @click="backToRecepcao"
            style="text-transform: none !important; font-weight: bolder"
          >
            <v-icon> mdi-arrow-left-bold </v-icon>
          </v-btn>
        </v-hover>
      </v-col>
      <v-col
        sm="1"
        md="1"
        lg="1"
        xl="1"
        cols="10"
        class="my-5 navy--text"
        align="start"
      >
        <h4>Protocolo:</h4>
      </v-col>
      <v-col cols="11" sm="7" md="5" lg="7" xl="6" class="my-5">
        <v-text-field
          label="Buscar Protocolo"
          placeholder="Buscar Protocolo"
          hide-details="auto"
          v-model="codeProtocol"
          dense
          solo
          flat
        ></v-text-field>
      </v-col>

      <!-- <v-col
        cols="11"
        sm="5"
        md="1"
        lg="1"
        xl="1"
        class="align-self-center my-5"
      > -->
      <!--        <div>-->
      <!--          <v-dialog max-width="720">-->
      <!--            <template v-slot:activator="{ on, attrs }">-->
      <!--              <v-hover v-slot="{ hover }">-->
      <!--                <v-btn-->
      <!--                  rounded-->
      <!--                  v-bind="attrs"-->
      <!--                  v-on="on"-->
      <!--                  :color="hover ? 'secondary' : 'primary'"-->
      <!--                  block-->
      <!--                  class="navy&#45;&#45;text"-->
      <!--                  style="text-transform: none !important; font-weight: bolder"-->
      <!--                  >Ler QR Code</v-btn-->
      <!--                >-->
      <!--              </v-hover>-->
      <!--            </template>-->
      <!--            <modal-qr-code />-->
      <!--          </v-dialog>-->
      <!--        </div>-->
      <!-- </v-col> -->

      <v-col
        cols="11"
        sm="6"
        md="2"
        lg="2"
        xl="2"
        class="align-self-center my-5"
      >
        <v-hover v-slot="{ hover }">
          <v-btn
            rounded
            :color="hover ? 'secondary' : 'primary'"
            block
            :disabled="!codeProtocol"
            :loading="isLoadingButton"
            class="navy--text"
            @click="reception(codeProtocol)"
            style="text-transform: none !important; font-weight: bolder"
            >Validar Protocolo</v-btn
          >
        </v-hover>
      </v-col>
    </v-row>
    <div v-if="this.receptionData.length">
      <v-row
        class="box-element justify-center align-center mx-3 my-auto mt-3 rounded-lg base_dark text--text"
        rounded
        v-for="protocol in getDataFromProtocol"
        :key="protocol.id"
      >
        <v-col cols="12" sm="10" md="4" lg="8" xl="3" class="mx-2">
          <v-icon style="font-size: 280px" color="primary">
            mdi-account-circle-outline
          </v-icon></v-col
        >

        <v-col
          cols="12"
          sm="9"
          md="8"
          lg="8"
          xl="8"
          class="py-2 mx-7 text-start align-center"
        >
          <v-row class="pa-0 ma-0">
            <h2>N. Protocolo: {{ protocol.protocolo }}</h2>
          </v-row>

          <v-row class="pa-0 ma-0">
            <span>Transportadora: {{ protocol.razaoSocial }}</span>
          </v-row>

          <v-row>
            <v-col>
              <h4>Detalhes do Motorista</h4>
              <v-row class="pa-0 ma-0">
                <span>Nome: {{ protocol.nomeMotorista }}</span>
              </v-row>
              <v-row class="pa-0 ma-0">
                <span>CPF: {{ protocol.cpfMotorista }}</span>
              </v-row>
            </v-col>

            <v-col>
              <h4>Detalhes da Coleta</h4>
              <v-row class="pa-0 ma-0">
                <span>Coleta: {{ protocol.coletaId }}</span>
              </v-row>
              <v-row class="pa-0 ma-0">
                <span
                  >Hora: {{ protocol.dataInicioEmbarque | formatDate }}
                  {{ protocol.dataInicioEmbarque | formatHour }} -
                  {{ protocol.dataFimEmbarque | formatHour }}</span
                >
              </v-row>
              <v-row class="pa-0 ma-0">
                <!-- <span
                  >Status Protocolo:
                  {{ (protocol.statusProtocolo || "").toUpperCase() }}</span
                > -->
                <span
                  >Status Coleta:
                  {{ (protocol.statusColeta || "").toUpperCase() }}</span
                >
              </v-row>
            </v-col>
          </v-row>

          <v-col class="pa-0 ma-0 mt-4">
            <h4>Detalhes do Veículo</h4>
            <v-row class="pa-0 ma-0">
              <span>Veículo: {{ protocol.tipoVeiculo }}</span>
            </v-row>
            <v-row class="pa-0 ma-0">
              <span>Placa: {{ protocol.placaVeiculo }}</span>
            </v-row>
          </v-col>
        </v-col>
        <div>
          <v-col class="align-self-center ma-5" cols="4">
            <v-hover v-slot="{ hover }">
              <v-btn
                rounded
                :disabled="
                  protocol.statusColeta !== 'AGUARDANDO_TRANSPORTADORA'
                "
                :color="hover ? 'secondary' : 'primary'"
                @click="onConfirmDriver(protocol.coletaId)"
                x-large
                :loading="isLoadingBtnDriver"
                block
                class="navy--text"
                style="text-transform: none !important; font-weight: bolder"
                max-width="220px"
                >Confirmar Motorista</v-btn
              >
            </v-hover>
          </v-col>
        </div>
      </v-row>
    </div>
    <div v-else>
      <v-row
        class="box-element justify-center align-center mx-3 my-auto mt-3 rounded-lg base_dark text--text"
        rounded
      >
        <v-col class="align-self-center ma-5" cols="4" v-if="!isLoadingButton">
          <span>Favor inserir o Número do protocolo.</span>
        </v-col>
        <v-col class="align-self-center ma-5" cols="4" v-if="isLoadingButton">
          <span>Aguarde enquanto buscamos informações do protocolo...</span>
        </v-col>
      </v-row>
    </div>
  </div>
  <v-col v-else>
    <v-progress-circular
      color="primary"
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-col>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
// import ModalQrCode from "../components/defaultUserComponents/reception/ModalQrCode.vue";

export default {
  // components: { ModalQrCode },
  name: "DetailsReception",
  data: () => ({
    isLoadingBtnDriver: false,
    isLoading: false,
    codeProtocol: "",
  }),
  mounted() {
    this.setDefaultState();
  },
  computed: {
    ...mapGetters("reception", ["getDataFromProtocol"]),
    ...mapState("reception", ["receptionData", "isLoadingButton"]),
  },
  methods: {
    ...mapActions("reception", [
      "reception",
      "confirmDriver",
      "setDefaultState",
    ]),
    async onConfirmDriver(coletaId) {
      this.isLoadingBtnDriver = true;
      await this.confirmDriver(coletaId);
      this.isLoadingBtnDriver = false;
    },
    backToRecepcao() {
      this.$router.replace({
        name: "Recepção",
      });
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
};
</script>
<style>
.box-element {
  background-color: #ffffff;
  min-height: 30px;
}
.h {
  font-weight: bolder;
}
</style>
