import { HttpRestService } from "@/services/http/HttpRestService";

export class ConferenciaBaiaRepository {
  static volumeDetails(payload) {
    return HttpRestService.get(`/baia/patio/detalhe/pedido/${payload}`);
  }

  static confirmVolume(pedidoid) {
    return HttpRestService.post(`/baia/patio/conferencia/pedido/${pedidoid}`);
  }
}
